Navbar.css .header {
    position: fixed;
    width: 100%;

    top: 0;
    left: 0;
    background-color: transparent;
    z-index: var(--z-fixed);
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
    /* height: 100vh; */

}
.main-box.scrolled {
    background-color: white;
    color: black;
  }

.nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.nav__cta {
    background-color: var(--first-color);
    color: var(--title-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 0.75rem 1.5rem;
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
}

.nav__link {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
    transition: color 0.4s;
}

.nav__link:hover {
    color: var(--first-color);
}

.nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.show-menu {
    right: 0;
}


@media screen and (max-width: 1150px) {
    .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: hsla(0, 0%, 6%, 0.2);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 80%;
        height: 100%;
        padding: 6rem 3rem 0;
        transition: right 0.4s;
    }
}

@media screen and (min-width: 1150px) {
    .nav {
        height: calc(var(--header-height) + 1rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list {
        flex-direction: row;
        column-gap: 2.5rem;
    }
}

.close-icon {
    /* width: 9cap; */
    font-size: 30px;
    margin: 25px;
    background-color: rgb(241, 85, 85);
}



/* Basic dropdown styles */

@media screen and (max-width:768px) {
    .drpdwn {
        width: 100%;
        border-bottom: 0.6px solid grey;
        font-size: 18px;
       
       

    }

    .drpdwn a{
        font-size: 20px;
        font-weight: 200;
        color: rgb(255, 123, 0);
    }

    .dropdown a{
        font-size: 20px;
        font-weight: 200;
        color: rgb(255, 123, 0);
    }

  

    .dropdown {
        font-weight: 200;
        width: 100%;
        border-bottom: 0.6px solid grey;
       
    }

    .dropdown-content a:hover {
        width: 100%;
        border-bottom: 0.6px solid grey;
        font-size: 25px;
        color: rgb(255, 98, 0);
    }


    .dropdown-content.show {
        /* display: block;  */
    }

    .dropdown-arrow {
        margin-left: 5px;
        /* Adjust spacing as needed */
        color: #999;
        /* Gray arrow color */
    }


}


.menucolor{
    font-size: 20px;
    font-weight: 200;
    color: rgb(255, 123, 0);
}




.headers {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white; /* Change this to match your design */
    z-index: 1000; /* Ensure it appears above other elements */
    overflow-y: auto; /* Allow scrolling if the content is tall */
    display: flex;
    flex-direction: column;
  }
  
  .header .d-flex {
    justify-content: space-between;
    padding: 5px; /* Adjust padding as needed */
  }
  
  .header .navigation {
    padding: 5px; /* Adjust padding as needed */
  }
  
  .header .close-btn {
    cursor: pointer;
  }
  
  .header .close-icon {
    font-size: 2rem; /* Adjust icon size as needed */
  }
  
  .header .navigation .dropdown-content {
    display: none;
  }
  
  .header .navigation .dropdown:hover .dropdown-content {
    display: block;
    position: relative;
  }
  
  .auto-container {
    position: static;
    max-width: 1200px;
    padding: -0.1px 15px;
    margin: -10px auto;
}