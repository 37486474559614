
@media screen and (max-width:768px) {
    
    .left-slide{
    width: 100%;
    height: 50vh;
    }
}


.vertical-slider {
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .slider-content {
    display: flex;
    flex-direction: column;
    height: calc(100% * 2); /* Double the height to ensure continuous scrolling */
    animation: scrollUp 10s linear infinite;
  }
  
  .slider-item {
    height: 35vw; /* Adjust based on the height of your images */
    margin-bottom: 3px; /* Space between items */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .slider-item img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  
  @keyframes scrollUp {
    
    0% { transform: translateY(40%); }
   
    100% { transform: translateY(-100%); }
  }
  

  .right-slide{
    padding: 20px;
   
    width: 100%;
  }

  
  .main-header.header-style-two.fixed-header .main-box {
    background-color: #ffffff;
    padding: 0;
}
  .vertical-carousel .carousel-inner {
    display: flex;
    flex-direction: column;
    animation: bottomToTop 6s infinite;
  }
  
  @keyframes bottomToTop {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .carousel-caption {
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    text-align: center;
  }
  
  

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    /* -moz-backface-visibility: hidden; */
}

.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
}

/* Carousel navigation buttons */
.owl-nav {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
    position: absolute;
    background: #444;
    color: #FFF;
    padding: 10px;
    cursor: pointer;
}

.owl-nav .owl-prev {
    left: 0;
}

.owl-nav .owl-next {
    right: 0;
}

/* Pagination dots */
.owl-dots {
    text-align: center;
    padding-top: 15px;
}

.owl-dot {
    display: inline-block;
    background: #444;
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.owl-dot.active {
    background: #FFF;
}

/* Custom styles for the sponsors section */
.sponsors-outer {
    padding: 20px 0;
    background-color: #f9f9f9;
}

.sponsors-carousel {
    margin: 0 auto;
    width: 80%;
}

.client-block {
    text-align: center;
    padding: 10px;
}

.client-block .image-box {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.client-block .image-box img {
    max-width: 100%;
    height: auto;
    display: block;
}


.midSpn{
    font-size: 35px;
    color: white;
}