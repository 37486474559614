.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}
.table th, .table td {
   border: 1px solid #ccc;
padding: 10px;
text-align: left;
    
}
.table th {
    background-color: #f2f2f2;
}