.page-wrapper {
    font-family: Arial, sans-serif;
  }
  
  .page-title {
    background-size: cover;
    background-position: center;
    padding: 50px, 0;
    text-align: center;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .page-title h1 {
    font-size: 36px;
    font-weight: bold;
  }
  
  .bread-crumb {
    margin-top: 20px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .bread-crumb li {
    margin: 0 10px;
    font-size: 14px;
    color: #ccc;
  }
  
  .bread-crumb li a {
    color: #ccc;
    text-decoration: none;
  }
  
  .contact-page-section {
    padding: 50px 0;
    text-align: center;
  }
  
  .form-column {
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .shadow-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .barcode-search-form {
    display: flex;
    align-items: center;
  }
  
  .barcode-input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-button {
    margin-left: 10px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  