/* General container styles */
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px;
}

/* .container {
  display: flex;
} */

.search-section {
  width: 25%;
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.card-section {
  width: 75%;
  padding: 10px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.cardd {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}

.card-header img {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 10px;
}

.card-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card-details {
  margin-top: 10px;
}

.card-location {
  font-size: 14px;
  color: #666;
}

.card-views {
  font-size: 14px;
  color: #666;
}

.search-button {
  background-color: #ffcc80;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
}

.search-button:hover {
  background-color: #ffb74d;
}


/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .card-grid {
    grid-template-columns: 1fr;
  }

  .search-section {
    width: 100%;
    margin-bottom: 20px;
    border-right: none;
  }
}


.exb_image{
  aspect-ratio: 3/2;
  object-fit: contain;
  /* mix-blend-mode:color; */
}