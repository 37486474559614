.main-header {
    position: relative;
  }

  .img-fluid{
    aspect-ratio: 3/2;
    object-fit: contain;
    /* mix-blend-mode:color; */
  }

   /* Keyframes for left-to-right sliding */
   @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
    /* Custom sizes for sponsor logos */
    .diamond-logo {
      width: 300px; /* Largest size */
      margin: 0 10px;
    }
    .gold-logo {
      width: 250px; /* Medium size */
      margin: 0 10px;
    }
    .silver-logo {
      width: 200px; 
      margin: 0 10px;
    }

  .silver-sponsor-slide {
    display: flex;
    justify-content: space-between;
    animation: slide 2s ease-in-out forwards;
  }

  .silver-sponsor-slide img {
    margin: 0 10px;
    width: 200px;
  }
  
  .mobile-nav-toggler {
    display: none;
  }
  .img-fluid{
    aspect-ratio: 3/2;
    object-fit: contain;
    /* mix-blend-mode:color; */
  }
  
  
  .navv {
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 2rem;
    /* height: 100vh; */
}

  @media (max-width: 768px) {
    .main-menu .navbar-collapse {
      display: none !important;
    }
  
    .mobile-nav-toggler {
      display: block;
      cursor: pointer;
    }
  
    .mobile-menu {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 1000;
      overflow-y: auto;
      transition: all 0.3s;
    }
  
    .mobile-menu.open {
      display: block;
    }
  
    .mobile-menu .close-btn {
      display: flex;
      justify-content: flex-end;
      padding: 20px;
    }
  
    .mobile-menu .menu-box {
      padding: 20px;
    }
  }
  
  .tab-pane.fade {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .tab-pane.fade.in.active {
    opacity: 1;
  }
  
